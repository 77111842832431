import Image from "components/atoms/Img";
import LinkRouter from "components/atoms/LinkRouter";
const Card = ({ imageSrc, title, href, subLink }) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center pb-2">
        <div className="mr-2">
          <Image
            src={imageSrc}
            width={36}
            height={36}
            alt={title}
            wrapper={{ className: "block" }}
          />
        </div>
        <LinkRouter
          href={href}
          className="inline-block text-sm font-medium uppercase tracking-[0.25px] text-bbc-brand-800 "
        >
          {title}
        </LinkRouter>
      </div>

      {subLink && (
        <div className="ml-[50px] flex flex-col">
          {subLink.map((el, idx) => (
            <LinkRouter
              key={idx}
              href={el.link}
              className="mb-3 inline-block text-sm font-medium uppercase tracking-[0.25px] text-bbc-brand-800"
            >
              {el.name}
            </LinkRouter>
          ))}
        </div>
      )}
    </div>
  );
};

export default function MegaItem({ className, data }) {
  return (
    <div className={` mb-4 ${className ? ` ${className}` : ""}`}>
      {data &&
        data.map((item, index) => {
          return (
            <div className="col " key={index}>
              <Card
                imageSrc={item.src}
                title={item.title}
                description={item.description}
                href={item.href}
                subLink={item.subLink}
              />
            </div>
          );
        })}
    </div>
  );
}
