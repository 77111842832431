import { useState } from "react";

import Dropdown from "components/common/Layout/Header/MenuComponents/Dropdow";

import LocationAccordion from "./Location";
import TaxRateAccordion from "./TaxRate";

export default function MegaMenuCountries() {
  const [countriesOpened, setCountriesOpened] = useState(false);

  return (
    <>
      <Dropdown
        title="Countries"
        classNameTitle="inline-block  font-medium"
        state={countriesOpened}
        onClick={() => setCountriesOpened(!countriesOpened)}
        content={
          <ul className="mb-2 pl-5">
            <li>
              <LocationAccordion />
            </li>
            <li>
              <TaxRateAccordion />
            </li>
          </ul>
        }
      />
    </>
  );
}
