import { Transition } from "@headlessui/react";

import { FiChevronDown } from "components/atoms/Icon/fi/FiChevronDown";

export default function Dropdown({
  onClick,
  state,
  content,
  title,
  classNameTitle,
}) {
  return (
    <>
      <div
        className="mb-4 flex cursor-pointer items-center justify-between"
        onClick={onClick}
      >
        <p className={classNameTitle}>{title}</p>

        <div
          className={`${
            state ? "rotate-180" : "rotate-0 "
          }   text-bbc-white transition-all duration-75 ease-linear`}
        >
          <FiChevronDown aria-hidden="true" />
        </div>
      </div>
      <Transition
        show={state}
        enter="transition-all duration-300"
        enterFrom="transform max-h-0 opacity-0"
        enterTo="transform max-h-[400px] opacity-100"
        leave="transition-all duration-200 "
        leaveFrom="transform max-h-[400px] opacity-50"
        leaveTo="transform max-h-0 opacity-0"
      >
        {content}
      </Transition>
    </>
  );
}
