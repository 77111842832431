import { countries, LOCATION_TYPES } from "common/countries";

import LinkRouter from "components/atoms/LinkRouter";
const LocationAmericas = () => {
  return (
    <>
      <h4 className="mb-6 text-xl font-semibold uppercase text-bbc-brand-800">
        The Americas
      </h4>
      <ul>
        {countries
          .filter((country) => country.location === LOCATION_TYPES.AMERICAS)
          .map((country, index) => (
            <li key={index} className=" mb-2 text-bbc-black last:mb-0 ">
              <LinkRouter
                href={country.href}
                className="inline-block hover:text-bbc-primary"
              >
                {country.label}
              </LinkRouter>
            </li>
          ))}
      </ul>
    </>
  );
};

const LocationAsiaPacific = () => {
  return (
    <>
      <h4 className="mb-6 text-xl font-semibold uppercase text-bbc-brand-800">
        Asia Pacific
      </h4>
      <ul className="">
        {countries
          .filter((country) => country.location === LOCATION_TYPES.ASIA_PACIFIC)
          .map((country, index) => (
            <li key={index} className=" mb-2 text-bbc-black last:mb-0 ">
              <LinkRouter
                href={country.href}
                className="inline-block hover:text-bbc-primary "
              >
                {country.label}
              </LinkRouter>
            </li>
          ))}
      </ul>
    </>
  );
};

const LocationEMEA = () => {
  return (
    <>
      <h4 className="mb-6 text-xl font-semibold uppercase text-bbc-brand-800">
        Europe, Middle East and Africa (EMEA)
      </h4>
      <ul className="">
        {countries
          .filter((country) => country.location === LOCATION_TYPES.EMEA)
          .map((country, index) => (
            <li key={index} className=" mb-2 text-bbc-black last:mb-0 ">
              <LinkRouter
                href={country.href}
                className="inline-block hover:text-bbc-primary "
              >
                {country.label}
              </LinkRouter>
            </li>
          ))}
      </ul>
    </>
  );
};

export default function LocationPanel() {
  return (
    <div className="col-12">
      <div className="row">
        <div className="col-4">
          <LocationAmericas />
        </div>
        <div className="col-4">
          <LocationAsiaPacific />
        </div>
        <div className="col-4">
          <LocationEMEA />
        </div>
      </div>
    </div>
  );
}
