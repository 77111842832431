import { Transition } from "@headlessui/react";

import MegaMenuCountries from "./MegaMenuCountries/index";
import ResourcesTab from "./MegaMenuResources/index";
import MegaMenuServices from "./MegaMenuServices/index";
export default function MegaMenuDesktop({
  tab,
  id,
  forwardedRef,
  locationRouter,
}) {
  return (
    <div id={id} className={(tab !== null && " ") || "hidden"}>
      <div ref={forwardedRef} className="container">
        <Transition
          show={tab === 0}
          unmount={false}
          enter="transition-all duration-200 ease-in"
          enterFrom="opacity-0 -translate-y-4  "
          enterTo="opacity-100  translate-y-0"
          leave="transition ease-out duration-200"
          leaveFrom="opacity-100 translate-y-10 translate-y-0"
          leaveTo="opacity-0 -translate-y-4 "
        >
          <MegaMenuServices
            locationRouter={locationRouter}
            className={tab === 0 ? "" : "  hidden"}
          />
        </Transition>
        {locationRouter === "offshore" && (
          <Transition
            unmount={false}
            show={tab === 1}
            enter="transition-all duration-200 ease-in"
            enterFrom="opacity-0 -translate-y-4 "
            enterTo="opacity-100  translate-y-0"
            leave="transition ease-out duration-200"
            leaveFrom="opacity-100 translate-y-10 translate-y-0"
            leaveTo="opacity-0 -translate-y-4 "
          >
            <MegaMenuCountries
              locationRouter={locationRouter}
              className={tab === 1 ? "" : " hidden "}
            />
          </Transition>
        )}

        <Transition
          show={tab === 2}
          enter="transition-all duration-200 ease-in"
          enterFrom="opacity-0 -translate-y-4  "
          enterTo="opacity-100  translate-y-0"
          leave="transition ease-out duration-200"
          leaveFrom="opacity-100 translate-y-10 translate-y-0"
          leaveTo="opacity-0 -translate-y-4 "
        >
          <ResourcesTab
            locationRouter={locationRouter}
            className={tab === 2 ? "" : " hidden"}
          />
        </Transition>
      </div>
    </div>
  );
}
