/* eslint-disable react/no-unknown-property */
import { Fragment, useContext, useEffect, useRef, useState } from "react";

import { useRouter } from "next/router";

import { Listbox, Transition } from "@headlessui/react";

import { LayoutContext } from "common/contexts";
// Data
import dataMenu from "common/data/menu/data.json";

import ButtonNew from "components/atoms/ButtonNew";
import { FaChevronDown } from "components/atoms/Icon/fa/FaChevronDown";
import { FaSearch } from "components/atoms/Icon/fa/FaSearch";
import { FaTimes } from "components/atoms/Icon/fa/FaTimes";
import Image from "components/atoms/Img";

import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";

import styles from "./Index.module.scss";

export const ChangePriceSiteList = [
  "/sg/company-incorporation-local",
  "/sg/company-incorporation-foreigners",
  "/sg/accounting-and-auditing",
  "/sg/company-secretary",
  "/sg/immigration-service",
  "/sg/open-bank-account",
];

export default function Header() {
  const router = useRouter();
  const { locationRouter, unit, currencyUnit, setCurrencyUnit, customMenu } =
    useContext(LayoutContext);
  const LocationInitial = dataMenu?.dataLanguage?.filter(
    (item) => item.location == locationRouter
  );
  const [selected, setSelected] = useState(LocationInitial[0]);
  const [listboxOptions, setListboxOptions] = useState(false);
  const [isOpenSearchBox, setIsOpenSearchBox] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inputSearchRef = useRef(null);

  const [listUnitOptions, setListUnitOptions] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    setIsOpenSearchBox(false);
  }, [router.asPath]);

  const handleSearchChange = (event) => {
    setSearchKey(event.target.value);
    // Event.preventdefault();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setSearchKey("");
      setIsLoading(true);

      // Router.push(`/${locationRouter}/search?key=${event.target.value}`);
      window.location.href = `/${locationRouter}/search?key=${event.target.value}`;
    }
  };
  const handleOnChangePriceUnit = (value) => {
    setCurrencyUnit((prev) => {
      return {
        previous: prev.id,
        current: value.id,
        ...value,
      };
    });
  };
  const renderDesktopSearchBox = () => {
    return (
      <>
        <div
          className="relative flex w-full items-center justify-center"
          style={{ height: "38px" }}
        >
          <Transition
            appear
            show={isOpenSearchBox}
            as={"div"}
            className="absolute w-1/2"
            style={{ zIndex: 80 }}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-all ease-out duration-700"
              enterFrom="opacity-0 scale-x-0 translate-x-full"
              enterTo="opacity-100 scale-x-100 translate-x-0"
              leave="transition-all ease-in duration-300"
              leaveFrom="opacity-100 scale-x-100 translate-x-0"
              leaveTo="opacity-0 scale-x-0 translate-x-full"
              afterEnter={() => {
                // Focus vào input sau khi enter animation chạy xong
                inputSearchRef.current?.focus();
              }}
            >
              <div className="inline-flex w-full items-center justify-center">
                <FaSearch
                  size={18}
                  className="mr-2 font-semibold text-bbc-neutral-600"
                />
                <style jsx>{`
                  /* Remove chrome clear button */
                  input[type="search"]::-webkit-search-decoration,
                  input[type="search"]::-webkit-search-cancel-button,
                  input[type="search"]::-webkit-search-results-button,
                  input[type="search"]::-webkit-search-results-decoration {
                    -webkit-appearance: none;
                  }
                `}</style>
                <input
                  // AutoFocus // Nếu dùng autoFocus sẽ làm mất enter animation của transition
                  ref={inputSearchRef}
                  type="search"
                  placeholder="Search"
                  value={searchKey}
                  className="ml-2 w-full border-none bg-transparent text-bbc-neutral-600 placeholder-bbc-neutral-600 outline-none transition-all duration-500 ease-out focus:ring-0"
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                />
                <ButtonNew
                  btnDisplay={"inline-flex justify-center items-center"}
                  othersClass="border-none bg-transparent hover:bg-transparent text-bbc-neutral-600 font-normal outline-none"
                  onClick={() => {
                    setIsOpenSearchBox(false);
                  }}
                >
                  <FaTimes
                    size={18}
                    className="mr-2 font-semibold text-bbc-neutral-600"
                  />
                </ButtonNew>
              </div>
            </Transition.Child>
          </Transition>
          <Transition
            show={!isOpenSearchBox}
            as={"div"}
            className="absolute w-full"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-x-0"
              enterTo="opacity-100 scale-x-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-x-100"
              leaveTo="opacity-0 scale-x-0"
            >
              <div className="inline-flex w-full items-center justify-end">
                <ButtonNew
                  btnDisplay={"inline-flex justify-center items-center"}
                  othersClass="border-none bg-transparent hover:bg-transparent text-bbc-neutral-600 font-normal outline-none"
                  onClick={() => {
                    setIsOpenSearchBox(true);
                  }}
                >
                  <FaSearch
                    size={18}
                    className="mr-2 font-semibold text-bbc-neutral-600"
                  />
                  <span className="block w-full text-base">Search</span>
                </ButtonNew>
              </div>
            </Transition.Child>
          </Transition>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const header = document.getElementById("header-01");
      const handleNavbar = () => {
        if (header) {
          if (
            document.body.scrollTop > 0 ||
            document.documentElement.scrollTop > 0
          ) {
            if (!header.classList.contains("fixed")) {
              header.classList.add("fixed");
              header.classList.remove("absolute");
            }
          } else {
            header.classList.add("absolute");
            header.classList.remove("fixed");
          }
        }
      };

      handleNavbar();
      window.addEventListener("scroll", handleNavbar);

      return () => {
        window.removeEventListener("scroll", handleNavbar);
      };
    }
  }, []);

  return (
    <>
      <header id="header-01" className="absolute top-0 left-0  z-50 w-full">
        <div className="hidden lg:block">
          <div className=" bg-bbc-gray ">
            <div className="container">
              <div className="row">
                <div
                  className="col-12 flex items-center  justify-between"
                  style={{ height: "38px" }}
                >
                  <div>
                    <Listbox value={selected} onChange={setSelected}>
                      <div
                        className="relative "
                        onMouseEnter={() => setListboxOptions(true)}
                        onMouseLeave={() => setListboxOptions(false)}
                      >
                        <Listbox.Button as="div" role="button">
                          <div
                            className={` flex items-center ${styles.listButton} `}
                          >
                            <Image
                              src={
                                selected?.src ||
                                "/assets/images/layout/flat-offshore-layout.png"
                              }
                              width={selected.width || 24}
                              height={selected.height || 24}
                              className="h-full w-full"
                              wrapper={{ className: "w-6 h-6" }}
                              alt={selected?.name}
                            />
                            <p className="ml-1.5 flex-1 whitespace-nowrap text-sm font-medium leading-6 text-white">
                              {selected.name}
                            </p>
                            <div
                              className={`${
                                listboxOptions ? "rotate-180" : "rotate-0 "
                              } ml-1.5 text-xs transition-all duration-150 ease-linear`}
                            >
                              <FaChevronDown className=" text-bbc-white" />
                            </div>
                          </div>
                        </Listbox.Button>

                        <Transition
                          as={Fragment}
                          show={listboxOptions}
                          enter="transition-all duration-300 ease-in"
                          enterFrom="opacity-0 -translate-y-5  "
                          enterTo="opacity-100  translate-y-0"
                          leave="transition ease-out duration-300"
                          leaveFrom="opacity-100 translate-y-10 translate-y-0"
                          leaveTo="opacity-0 -translate-y-4 "
                        >
                          <Listbox.Options className={`${styles.listOptions}`}>
                            {dataMenu?.dataLanguage
                              ?.filter(
                                (item) => item.location !== locationRouter
                              )
                              .map((countries, index) => {
                                return (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active }) =>
                                      `  ${styles.listOption}   ${
                                        active ? "" : ""
                                      }`
                                    }
                                    value={countries}
                                  >
                                    <a
                                      href={countries.href}
                                      className="flex w-full cursor-pointer items-center  justify-start text-bbc-black  "
                                    >
                                      <Image
                                        src={countries?.src}
                                        width={countries.width || 24}
                                        height={countries.height || 24}
                                        className="w-full "
                                        wrapper={{
                                          className: "w-6 h-6",
                                        }}
                                        alt={countries?.name}
                                      />

                                      <p className=" ml-2 text-base font-medium hover:text-bbc-primary">
                                        {countries.name}
                                      </p>
                                    </a>
                                  </Listbox.Option>
                                );
                              })}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                  {renderDesktopSearchBox()}
                  {locationRouter == "sg" &&
                    ChangePriceSiteList.includes(router.asPath) && (
                      <div>
                        <Listbox
                          value={currencyUnit}
                          onChange={handleOnChangePriceUnit}
                        >
                          <div
                            className="relative "
                            onMouseEnter={() => setListUnitOptions(true)}
                            onMouseLeave={() => setListUnitOptions(false)}
                          >
                            <Listbox.Button as="div" role="button">
                              <div
                                className={` flex items-center ${styles.listButton} `}
                              >
                                <div className="grid h-6 w-6 place-content-center rounded-full bg-bbc-yellow-500 text-[10px] font-semibold leading-6">
                                  {currencyUnit.value}
                                </div>
                                <p className="ml-1.5 flex-1 whitespace-nowrap text-sm font-medium leading-6 text-white">
                                  {currencyUnit.name}
                                </p>
                                <div
                                  className={`${
                                    listUnitOptions ? "rotate-180" : "rotate-0 "
                                  } ml-1.5 text-xs transition-all duration-150 ease-linear`}
                                >
                                  <FaChevronDown className=" text-bbc-white" />
                                </div>
                              </div>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              show={listUnitOptions}
                              enter="transition-all duration-300 ease-in"
                              enterFrom="opacity-0 -translate-y-5  "
                              enterTo="opacity-100  translate-y-0"
                              leave="transition ease-out duration-300"
                              leaveFrom="opacity-100 translate-y-10 translate-y-0"
                              leaveTo="opacity-0 -translate-y-4 "
                            >
                              <Listbox.Options
                                className={`${styles.listOptions}`}
                              >
                                {unit
                                  ?.filter(
                                    (item) => item.id !== currencyUnit.id
                                  )
                                  .map((el, index) => {
                                    return (
                                      <Listbox.Option
                                        key={index}
                                        className={({ active }) =>
                                          `flex ${styles.listOption}   ${
                                            active ? "" : ""
                                          }`
                                        }
                                        value={el}
                                      >
                                        <div className="flex w-full cursor-pointer items-center  justify-start text-bbc-black">
                                          <div className="grid h-6 w-6 place-content-center rounded-full bg-bbc-yellow-500 text-[10px] font-semibold leading-6">
                                            {el.value}
                                          </div>
                                          <p className="ml-1.5 flex-1 whitespace-nowrap text-sm font-medium leading-6 ">
                                            {el.name}
                                          </p>
                                        </div>
                                      </Listbox.Option>
                                    );
                                  })}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                    )}
                  <Transition show={isOpenSearchBox} as={Fragment}>
                    <div className="relative h-screen">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div
                          className={`fixed inset-0 top-0 w-full ${styles.searchButton} `}
                          onClick={() => {
                            setSearchKey("");
                            setIsOpenSearchBox(false);
                          }}
                        >
                          {isLoading && (
                            <div
                              id="search-header"
                              className="absolute inset-0 inline-flex items-center justify-center text-bbc-white"
                            >
                              <span className="inline-block h-[100px] w-[100px] animate-spin rounded-full border-[12px] border-bbc-white border-b-bbc-brand-500"></span>
                            </div>
                          )}
                        </div>
                      </Transition.Child>
                    </div>
                  </Transition>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MenuDesktop locationRouter={locationRouter} customMenu={customMenu} />
        <MenuMobile locationRouter={locationRouter} />
      </header>
    </>
  );
}
