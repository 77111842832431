import dataMenu from "common/data/menu/data.json";

import MegaItem from "components/common/Layout/Header/MenuComponents/MegaItem";

export default function ResourcesTab({ className, locationRouter }) {
  return (
    <div className={` ${className ? ` ${className}` : ""}`}>
      {locationRouter === "offshore" && (
        <MegaItem data={dataMenu.dataOffshore.resources} />
      )}
      {/* hongkong */}
      {locationRouter === "hk" && (
        <MegaItem data={dataMenu.dataHongkong.resources} />
      )}
      {/* singapore */}
      {locationRouter === "sg" && (
        <MegaItem data={dataMenu.dataSingapore.resources} />
      )}
      {/* vietnam */}
      {locationRouter === "vn" && (
        <div className="m-auto lg:col-6">
          <MegaItem data={dataMenu.dataVietNam.resources} />
        </div>
      )}
    </div>
  );
}
