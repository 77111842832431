export const LOCATION_TYPES = {
  AMERICAS: "AMERICAS",
  ASIA_PACIFIC: "ASIA_PACIFIC",
  EMEA: "EMEA",
};

export const TAX_RATE_TYPES = {
  ZERO: "ZERO",
  LOW: "LOW",
};

export const countries = [
  {
    id: 1,
    label: "Anguilla",
    value: "Anguilla",
    location: LOCATION_TYPES.AMERICAS,
    taxRate: TAX_RATE_TYPES.ZERO,
    href: "/company-formation/anguilla",
    icon: "ai",
    zeroCount: 0,
  },
  {
    id: 2,
    label: "Bahamas",
    value: "Bahamas",
    location: LOCATION_TYPES.AMERICAS,
    taxRate: TAX_RATE_TYPES.ZERO,
    href: "/company-formation/bahamas",
    icon: "bs",
    zeroCount: 1,
  },
  {
    id: 3,
    label: "Belize",
    value: "Belize",
    location: LOCATION_TYPES.AMERICAS,
    taxRate: TAX_RATE_TYPES.LOW,
    href: "/company-formation/belize",
    icon: "bz",
    zeroCount: -1,
  },
  {
    id: 4,
    label: "British Virgin Islands",
    value: "British Virgin Islands",
    location: LOCATION_TYPES.AMERICAS,
    taxRate: TAX_RATE_TYPES.ZERO,
    href: "/company-formation/bvi",
    icon: "vg",
    zeroCount: 2,
  },
  {
    id: 5,
    label: "Cayman Islands",
    value: "Cayman Islands",
    location: LOCATION_TYPES.AMERICAS,
    taxRate: TAX_RATE_TYPES.ZERO,
    href: "/company-formation/cayman-islands",
    icon: "ky",
    zeroCount: 3,
  },
  {
    id: 6,
    label: "Delaware (the US)",
    value: "Delaware (the US)",
    location: LOCATION_TYPES.AMERICAS,
    taxRate: TAX_RATE_TYPES.ZERO,
    href: "/company-formation/delaware",
    icon: "us",
    zeroCount: 4,
  },
  {
    id: 7,
    label: "Panama",
    value: "Panama",
    location: LOCATION_TYPES.AMERICAS,
    taxRate: TAX_RATE_TYPES.ZERO,
    href: "/company-formation/panama",
    icon: "pa",
    zeroCount: 8,
  },
  {
    id: 8,
    label: "St. Kitts & Nevis",
    value: "St. Kitts & Nevis",
    location: LOCATION_TYPES.AMERICAS,
    taxRate: TAX_RATE_TYPES.ZERO,
    href: "/company-formation/saint-kitts-and-nevis",
    icon: "kn",
    zeroCount: 11,
  },
  {
    id: 9,
    label: "St. Vincent & Grenadines",
    value: "St. Vincent & Grenadines",
    location: LOCATION_TYPES.AMERICAS,
    taxRate: TAX_RATE_TYPES.ZERO,
    href: "/company-formation/saint-vincent-and-grenadines",
    icon: "vc",
    zeroCount: 12,
  },
  {
    id: 11,
    label: "Marshall Islands",
    value: "Marshall Islands",
    location: LOCATION_TYPES.ASIA_PACIFIC,
    taxRate: TAX_RATE_TYPES.ZERO,
    href: "/company-formation/marshall-islands",
    icon: "mh",
    zeroCount: 6,
  },
  {
    id: 12,
    label: "Samoa",
    value: "Samoa",
    location: LOCATION_TYPES.ASIA_PACIFIC,
    taxRate: TAX_RATE_TYPES.ZERO,
    href: "/company-formation/samoa",
    icon: "ws",
    zeroCount: 9,
  },
  {
    id: 15,
    label: "Cyprus",
    value: "Cyprus",
    location: LOCATION_TYPES.EMEA,
    taxRate: TAX_RATE_TYPES.LOW,
    href: "/company-formation/cyprus",
    icon: "cy",
    zeroCount: -1,
  },
  {
    id: 17,
    label: "Mauritius",
    value: "Mauritius",
    location: LOCATION_TYPES.EMEA,
    taxRate: TAX_RATE_TYPES.ZERO,
    href: "/company-formation/mauritius",
    icon: "mu",
    zeroCount: 7,
  },
  {
    id: 18,
    label: "Seychelles",
    value: "Seychelles",
    location: LOCATION_TYPES.EMEA,
    taxRate: TAX_RATE_TYPES.ZERO,
    href: "/company-formation/seychelles",
    icon: "sc",
    zeroCount: 10,
  },
  {
    id: 19,
    label: "Ras Al Khaimah (UAE)",
    value: "Ras Al Khaimah (UAE)",
    location: LOCATION_TYPES.EMEA,
    taxRate: TAX_RATE_TYPES.ZERO,
    href: "/company-formation/rak",
    icon: "ae",
    zeroCount: 13,
  },
  {
    id: 20,
    label: "United Kingdom",
    value: "United Kingdom",
    location: LOCATION_TYPES.EMEA,
    taxRate: TAX_RATE_TYPES.LOW,
    href: "/company-formation/united-kingdom",
    icon: "gb",
    zeroCount: -1,
  },
];
