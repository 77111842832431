import { useState } from "react";

import dataMenu from "common/data/menu/data.json";

import Image from "components/atoms/Img";
import LinkRouter from "components/atoms/LinkRouter";
import Dropdown from "components/common/Layout/Header/MenuComponents/Dropdow";
import MegaItemMobile from "components/common/Layout/Header/MenuComponents/MegaItemMobile";

export default function MegaMenuResources({ locationRouter }) {
  const [resOpened, setResOpened] = useState(false);

  return (
    <>
      <Dropdown
        title="Resources"
        paddingBottom="pb-4"
        classNameTitle="inline-block  font-medium"
        state={resOpened}
        onClick={() => setResOpened(!resOpened)}
        content={
          <>
            {locationRouter === "offshore" && (
              <div>
                <ul className=" pb-4">
                  <li className="mb-2 flex items-center uppercase ">
                    <Image
                      src="/assets/images/layout/resources1-layout.svg"
                      width={36}
                      height={36}
                      alt="Articles"
                    />
                    <LinkRouter
                      href="/articles "
                      className="pl-1 text-sm font-medium leading-7 text-bbc-brand-800"
                    >
                      Articles
                    </LinkRouter>
                  </li>
                  <li className="mb-2 flex items-center uppercase ">
                    <Image
                      src="/assets/images/layout/resources2-layout.svg"
                      width={36}
                      height={36}
                      alt="Articles"
                    />
                    <LinkRouter
                      href="/news "
                      className="pl-1 text-sm font-medium leading-7 text-bbc-brand-800"
                    >
                      News
                    </LinkRouter>
                  </li>
                  <li className="mb-2 flex items-center uppercase ">
                    <Image
                      src="/assets/images/layout/resources3-layout.svg"
                      width={36}
                      height={36}
                      alt="Articles"
                    />
                    <LinkRouter
                      href="/announcements "
                      className="pl-1 text-sm font-medium leading-7 text-bbc-brand-800"
                    >
                      Announcements
                    </LinkRouter>
                  </li>
                  <li className="flex  items-center uppercase ">
                    <Image
                      src="/assets/images/layout/resources4-layout.svg"
                      width={36}
                      height={36}
                      alt="Articles"
                    />
                    <LinkRouter
                      href="/guide-and-tool "
                      className="pl-1 text-sm font-medium leading-7 text-bbc-brand-800"
                    >
                      guide and tool
                    </LinkRouter>
                  </li>
                </ul>
              </div>
            )}
            {/* hongkong */}
            {locationRouter === "hk" && (
              <div>
                <MegaItemMobile data={dataMenu.dataHongkong.resources} />
              </div>
            )}
            {/* sg */}
            {locationRouter === "sg" && (
              <div>
                <MegaItemMobile data={dataMenu.dataSingapore.resources} />
              </div>
            )}
            {/* VIet */}
            {locationRouter === "vn" && (
              <div>
                <MegaItemMobile data={dataMenu.dataVietNam.resources} />
              </div>
            )}
          </>
        }
      />
    </>
  );
}
