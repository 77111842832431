// eslint-disable

import { useContext } from "react";

import numberFormat from "common/source-function/numberFormat";

import { LayoutContext } from "./contexts";

const exchangeData = [
  {
    id: 1,
    name: "bbcincorp.com",
    currency_id: 3,
    min_amount: 1,
    min_unit: 10,
    Currency: {
      id: 3,
      name: "Hong Kong dollar",
      code: "HKD",
      symbol: "$",
      exchange_rate: 7.86,
      is_deleted: false,
    },
  },
  {
    id: 2,
    name: "bbcincorp.sg",
    currency_id: 6,
    min_amount: 1,
    min_unit: 10,
    Currency: {
      id: 6,
      name: "Singapore dollar",
      code: "SGD",
      symbol: "$",
      exchange_rate: 1.33,
      is_deleted: false,
    },
  },
];

export const EXCHANGE = [
  {
    id: 1,
    name: "USD",
    value: "US$",
  },
  {
    id: 6,
    name: "SGD",
    value: "SG$",
  },
];

const EXCHANGE_RATE = [
  {
    from: 1,
    to: 6,
    rate: 1.33,
  },
];

const DEFAULT_UNIT = EXCHANGE[0].id;

export function isEqualString(a, b) {
  return parseInt(a, 10) === parseInt(b, 10);
}

/** Find Unit From Exchange
 * const findUnitFromExchange = (unitId) => {
  return EXCHANGE_RATE.find((item) => item.id === unitId);
};
 */

const findUnitFromRate = (from, to) => {
  // return EXCHANGE_RATE.find((item) => {
  //   const exchangeSumId = parseInt(item.from, 10) + parseInt(item.to, 10);
  //   const paramSumId = parseInt(from, 10) + parseInt(to, 10);

  //   return exchangeSumId === paramSumId;
  // });
  const findCurrency = exchangeData.find((item) => {
    const exchangeSumId = DEFAULT_UNIT + parseInt(item.currency_id, 10);
    const paramSumId = parseInt(from, 10) + parseInt(to, 10);

    return exchangeSumId === paramSumId;
  });

  return {
    from,
    to,
    min_unit: findCurrency.min_unit,
    min_amount: findCurrency.min_amount,
    rate: findCurrency?.Currency.exchange_rate,
  };
};

const exchangeCalculator = (amount, ratio, min_unit, min_amount) => {
  let amountEx = Math.abs(amount) * ratio;

  amountEx =
    (Math.floor(amountEx / min_unit) +
      Math.round((amountEx % min_unit) / min_unit)) *
    min_unit;

  if (amountEx < min_amount) amountEx = min_amount;

  // Service zero fee

  if (amount == 0) amountEx = 0;
  if (amount < 0) {
    amountEx *= amountEx * -1;
  }

  return amountEx;
};

export default function priceConvert({ from, to, price }) {
  const FILTER_RATE = findUnitFromRate(from, to);

  if (isEqualString(FILTER_RATE?.from, from)) {
    // Return Math.round(price * FILTER_RATE.rate);
    return exchangeCalculator(
      price,
      FILTER_RATE.rate,
      FILTER_RATE.min_unit,
      FILTER_RATE.min_amount
    );
  }

  if (isEqualString(FILTER_RATE?.from, to)) {
    // Return Math.round(price / FILTER_RATE.rate);
    return price;
  }
}
export function LocalStorageAutoConvertUnit(unit) {
  const { currencyUnit } = useContext(LayoutContext);

  if (
    typeof window === "undefined" ||
    (unit && isEqualString(unit, currencyUnit.id))
  ) {
    return currencyUnit.value;
  }
  const PRICE_UNIT = JSON.parse(window.localStorage.getItem("price_unit"));

  if (PRICE_UNIT?.previous) {
    return PRICE_UNIT.value;
  }

  return currencyUnit.value;
}

export function LocalStorageAutoConvertPrice(price, initialUnit) {
  const { currencyUnit } = useContext(LayoutContext);

  if (
    typeof window === "undefined" ||
    isEqualString(initialUnit, currencyUnit.id)
  ) {
    return numberFormat(price);
  }
  const PRICE_UNIT = JSON.parse(window.localStorage.getItem("price_unit"));

  if (PRICE_UNIT?.previous) {
    return numberFormat(
      priceConvert({
        from: PRICE_UNIT.previous,
        to: PRICE_UNIT.current,
        price,
      })
    );
  }

  return numberFormat(
    priceConvert({
      from: initialUnit,
      to: DEFAULT_UNIT,
      price,
    })
  );
}

export function AutoConvertPriceWithUnit(price, initialUnit) {
  return (
    LocalStorageAutoConvertUnit(initialUnit) +
    LocalStorageAutoConvertPrice(price, initialUnit)
  );
}

export function convertPriceOnString(pricing) {
  if (pricing.length > 0) {
    return pricing;
  }

  if (!pricing.paramsContent) {
    return pricing.content;
  }

  const cloneParams = { ...pricing.paramsContent };

  for (const key in cloneParams) {
    // eslint-disable-next-line no-prototype-builtins
    if (cloneParams.hasOwnProperty(key)) {
      cloneParams[key] = AutoConvertPriceWithUnit(
        parseInt(cloneParams[key], 10),
        pricing.unit
      );
    }
  }
  const names = Object.keys(cloneParams);
  const vals = Object.values(cloneParams);

  // eslint-disable-next-line no-new-func
  return new Function(...names, `return \`${pricing.content}\`;`)(...vals);
}
