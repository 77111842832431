import React from "react";

import { Transition } from "@headlessui/react";

import { FiChevronDown } from "components/atoms/Icon/fi/FiChevronDown";
export default function Dropdown({
  onClick,
  state,
  content,
  title,
  classNameTitle,
  paddingBottom,
}) {
  return (
    <>
      <div
        className={`flex cursor-pointer items-center justify-between ${
          paddingBottom ? paddingBottom : "pb-4"
        }`}
        onClick={onClick}
      >
        <p className={classNameTitle}>{title}</p>
        <div
          className={` text-bbc-black transition-all duration-75 ease-linear ${
            state ? " rotate-180 " : "rotate-0"
          }`}
        >
          <FiChevronDown aria-hidden="true" size={16} />
        </div>
      </div>
      <Transition
        show={state}
        enter="transition-all duration-300"
        enterFrom="transform max-h-0 opacity-0"
        enterTo="transform max-h-[400px] opacity-100"
        leave="transition-all duration-200 "
        leaveFrom="transform max-h-[400px] opacity-50"
        leaveTo="transform max-h-0 opacity-0"
      >
        {content}
      </Transition>
    </>
  );
}
