import { useState } from "react";

import dataMenu from "common/data/menu/data.json";

import Image from "components/atoms/Img";
import LinkRouter from "components/atoms/LinkRouter";
import Dropdown from "components/common/Layout/Header/MenuComponents/Dropdow";
import MegaItemMobile from "components/common/Layout/Header/MenuComponents/MegaItemMobile";

export default function MegaMenuServices({ locationRouter }) {
  const [serOpened, setSerOpened] = useState(false);

  return (
    <>
      <Dropdown
        title="Services"
        classNameTitle="inline-block  font-medium"
        state={serOpened}
        onClick={() => setSerOpened(!serOpened)}
        content={
          <>
            {locationRouter === "offshore" && (
              <div>
                <div className="flex items-center pb-2">
                  <Image
                    src="/assets/images/layout/iconSerives_menu-layout.svg"
                    width={36}
                    height={36}
                    alt="Corporate Secretary"
                  />
                  <p className="ml-1 text-sm font-medium uppercase text-bbc-brand-800">
                    Corporation Secretary
                  </p>
                </div>
                <ul className="pl-10">
                  {dataMenu.dataOffshore?.secretaries.map(
                    (secretary, index) => (
                      <li key={index} className="pb-2 text-sm leading-6 ">
                        <LinkRouter href={secretary.href}>
                          {secretary.title}
                        </LinkRouter>
                      </li>
                    )
                  )}
                </ul>
                <div className="flex items-center pb-2">
                  <Image
                    src="/assets/images/layout/iconSerives1_menu-layout.svg"
                    width={36}
                    height={36}
                    alt="Corporate Secretary"
                  />
                  <p className="ml-1 text-sm font-medium uppercase text-bbc-brand-800">
                    More
                  </p>
                </div>
                <ul className="pl-10">
                  {dataMenu.dataOffshore?.rightServices.map(
                    (service, index) => (
                      <li
                        key={index}
                        className="pb-2 text-sm leading-6 first:pt-0 last:pb-4"
                      >
                        <LinkRouter href={service.href} rel="dofollow">
                          {service.title}
                        </LinkRouter>
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
            {/* hongkong */}
            {locationRouter === "hk" && (
              <div>
                <MegaItemMobile data={dataMenu.dataHongkong.services} />
              </div>
            )}
            {/* sing */}
            {locationRouter === "sg" && (
              <div>
                <MegaItemMobile data={dataMenu.dataSingapore.services} />
              </div>
            )}
            {/* hongkong */}
            {locationRouter === "vn" && (
              <div>
                <MegaItemMobile data={dataMenu.dataVietNam.services} />
              </div>
            )}
          </>
        }
      />
    </>
  );
}
