import { useState } from "react";

import { FiChevronDown } from "components/atoms/Icon/fi/FiChevronDown";

// Import styles from "./Index.module.scss";
import LocationPanel from "./Location/index";
import TaxRatePanel from "./TaxRate/index";

export default function MegaMenuCountries({ className }) {
  const [currTab, setCurrTab] = useState(0);

  return (
    <div
      className={`shadowMenu row mt-5  rounded bg-white py-[30px]  px-4 ${
        className ? ` ${className}` : ""
      }`}
    >
      <ul className={" col-2 "}>
        <li
          className={`flex cursor-pointer items-center justify-between  border-b border-bbc-silver pb-5 ${
            currTab === 0 ? "text-bbc-primary " : ""
          }`}
          onMouseEnter={() => setCurrTab(0)}
        >
          <p className="font-medium">Location</p>
          <FiChevronDown
            className={` -rotate-90 font-normal ${
              currTab === 0 ? "text-bbc-primary " : "text-bbc-silver"
            }`}
          />
        </li>
        <li
          className={`flex cursor-pointer items-center justify-between  pt-5 font-medium ${
            currTab === 1 ? " text-bbc-primary  " : ""
          }`}
          onMouseEnter={() => setCurrTab(1)}
        >
          <p className="font-medium">Tax rate</p>
          <FiChevronDown
            className={`-rotate-90 font-normal ${
              currTab === 1 ? "text-bbc-primary " : "text-bbc-silver"
            }`}
          />
        </li>
      </ul>
      <div className="col-1 flex justify-center">
        <div className="h-full w-[1px] bg-bbc-silver"></div>
      </div>
      <div className={`row col-9 mx-0 pl-0 ${currTab === 0 ? "" : "hidden"}`}>
        <LocationPanel />
      </div>
      <div className={`row col-9 mx-0 pl-0 ${currTab === 1 ? "" : "hidden"}`}>
        <TaxRatePanel />
      </div>
    </div>
  );
}
