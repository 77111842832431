import dataMenu from "common/data/menu/data.json";

import { HiOutlineChevronDoubleRight } from "components/atoms/Icon/hi/HiOutlineChevronDoubleRight";
import Image from "components/atoms/Img";
import LinkRouter from "components/atoms/LinkRouter";
import MegaItem from "components/common/Layout/Header/MenuComponents/MegaItem";

export default function MegaMenuServices({ className, locationRouter }) {
  return (
    <div className={` ${className ? ` ${className}` : ""}`}>
      {locationRouter === "offshore" && (
        <div className=" shadowMenu row mt-5 justify-between rounded-xl bg-bbc-white py-8">
          <div className="col-4 pr-20">
            <Image
              src="/assets/images/layout/iconSerives_menu-layout.svg"
              width={36}
              height={36}
              className="mb-4"
              alt="Corporate Secretary"
            />
            <div>
              <p className="mb-4 text-xl font-semibold uppercase text-bbc-brand-800 ">
                Corporate Services
              </p>
              <p className="mb-6  text-bbc-neutral-800">
                Set up and manage your oversea company across the globe
              </p>
              <div>
                {dataMenu.dataOffshore?.secretaries.map((secretary, index) => (
                  <div
                    key={index}
                    className="  flex items-center border-b border-bbc-silver py-4 first:pt-0 last:border-0 last:pb-0"
                  >
                    <LinkRouter
                      href={secretary.href}
                      rel="dofollow"
                      className=" group flex w-full items-center "
                    >
                      <p className=" font-medium group-hover:text-bbc-primary group-hover:transition-all group-hover:duration-75 group-hover:ease-linear">
                        {secretary.title}
                      </p>
                      <HiOutlineChevronDoubleRight
                        size={16}
                        className="ml-2 hidden text-bbc-primary transition-all  duration-75 ease-linear group-hover:block"
                      />
                    </LinkRouter>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-8 pr-11">
            <div className="mb-4">
              <Image
                src="/assets/images/layout/iconSerives1_menu-layout.svg"
                width={36}
                height={36}
                alt="Corporate Secretary"
              />
            </div>

            <p className="mb-4 text-xl font-semibold uppercase text-bbc-brand-800 ">
              MORE
            </p>
            <div>
              {dataMenu.dataOffshore?.rightServices.map((service, index) => (
                <div
                  key={index}
                  className="group cursor-pointer border-b border-bbc-silver py-4 text-bbc-black first:pt-0 last:border-0 last:pb-0"
                >
                  <div className="flex items-center">
                    <LinkRouter
                      rel="dofollow"
                      href={service.href}
                      className="font-medium group-hover:text-bbc-primary group-hover:transition-all group-hover:duration-75 group-hover:ease-linear"
                    >
                      {service.title}
                    </LinkRouter>
                    <HiOutlineChevronDoubleRight
                      size={16}
                      className="ml-2 hidden text-bbc-primary transition-all  duration-75 ease-linear group-hover:block"
                    />
                  </div>
                  <p className="text-bbc-neutral-800 group-hover:text-bbc-black">
                    {service.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* hongkong */}
      {locationRouter === "hk" && (
        <MegaItem data={dataMenu.dataHongkong.services} />
      )}
      {/* singapore */}
      {locationRouter === "sg" && (
        <MegaItem data={dataMenu.dataSingapore.services} />
      )}
      {/* vietnam */}
      {locationRouter === "vn" && (
        <MegaItem data={dataMenu.dataVietNam.services} />
      )}
    </div>
  );
}
