import { useState } from "react";

import { countries, LOCATION_TYPES } from "common/countries";

import LinkRouter from "components/atoms/LinkRouter";
import Dropdown from "components/common/Layout/Header/MenuComponents/Dropdow";

// TheAmericasAccordion

const TheAmericasAccordion = () => {
  const [americasOpened, setAmericasOpened] = useState(false);

  return (
    <>
      <Dropdown
        title="The Americas"
        paddingBottom="pb-2"
        classNameTitle="text-bbc-brand-800 leading-bbc-26  text-sm  font-medium uppercase"
        state={americasOpened}
        onClick={() => setAmericasOpened(!americasOpened)}
        content={
          <div className="mb-3 flex">
            <ul className="w-1/2  pr-1">
              {countries
                .filter(
                  (country) => country.location === LOCATION_TYPES.AMERICAS
                )
                .slice(0, 5)
                .map((country, index) => (
                  <li key={index} className="mb-2 last:m-0 ">
                    <LinkRouter
                      className="inline-block text-sm leading-6 text-bbc-black"
                      href={country.href}
                    >
                      {country.label}
                    </LinkRouter>
                  </li>
                ))}
            </ul>
            <ul className="w-1/2 pl-1 ">
              {countries
                .filter(
                  (country) => country.location === LOCATION_TYPES.AMERICAS
                )
                .slice(5)
                .map((country, index) => (
                  <li key={index} className=" mb-2">
                    <LinkRouter
                      className="inline-block text-sm leading-6 text-bbc-black"
                      href={country.href}
                    >
                      {country.label}
                    </LinkRouter>
                  </li>
                ))}
            </ul>
          </div>
        }
      />
    </>
  );
};

// AsiaPacificAccordion

const AsiaPacificAccordion = () => {
  const [APOpened, setAPOpened] = useState(false);

  return (
    <>
      <Dropdown
        title="Asia Pacific"
        paddingBottom="pb-2"
        classNameTitle=" text-bbc-brand-800 text-sm leading-bbc-26 font-medium uppercase"
        state={APOpened}
        onClick={() => setAPOpened(!APOpened)}
        content={
          <ul className="mb-3 flex">
            {countries
              .filter(
                (country) => country.location === LOCATION_TYPES.ASIA_PACIFIC
              )
              .map((country, index) => (
                <li key={index} className=" col-6 last:pl-1">
                  <LinkRouter
                    className="inline-block  text-sm leading-6"
                    href={country.href}
                  >
                    {country.label}
                  </LinkRouter>
                </li>
              ))}
          </ul>
        }
      />
    </>
  );
};

// EMEAAccordion

const EMEAAccordion = () => {
  const [EMEAOpened, setEMEAOpened] = useState(false);

  return (
    <>
      <Dropdown
        title="Europe, Middle East and Africa (EMEA)"
        paddingBottom="pb-2"
        classNameTitle="text-bbc-brand-800 text-sm leading-bbc-26  font-medium uppercase"
        state={EMEAOpened}
        onClick={() => setEMEAOpened(!EMEAOpened)}
        content={
          <div className="mb-3 flex">
            <ul className="w-1/2  pr-1">
              {countries
                .filter((country) => country.location === LOCATION_TYPES.EMEA)
                .slice(0, 3)
                .map((country, index) => (
                  <li key={index} className="mb-2 last:m-0">
                    <LinkRouter
                      className="inline-block text-sm leading-6"
                      href={country.href}
                    >
                      {country.label}
                    </LinkRouter>
                  </li>
                ))}
            </ul>
            <ul className="w-1/2 pl-1 ">
              {countries
                .filter((country) => country.location === LOCATION_TYPES.EMEA)
                .slice(3)

                .map((country, index) => (
                  <li key={index} className="mb-2 last:m-0">
                    <LinkRouter
                      className="inline-block text-sm leading-6"
                      href={country.href}
                    >
                      {country.label}
                    </LinkRouter>
                  </li>
                ))}
            </ul>
          </div>
        }
      />
    </>
  );
};

// Location

export default function LocationAccordion() {
  const [locationOpened, setLocationOpened] = useState(false);

  return (
    <>
      <Dropdown
        title="Location"
        paddingBottom="pb-2"
        classNameTitle="inline-block  font-medium "
        state={locationOpened}
        onClick={() => setLocationOpened(!locationOpened)}
        content={
          <ul className="list-unstyled m-0 pl-4">
            <li>
              <TheAmericasAccordion />
            </li>
            <li>
              <AsiaPacificAccordion />
            </li>
            <li>
              <EMEAAccordion />
            </li>
          </ul>
        }
      />
    </>
  );
}
