import { createElement } from "react";

import Head from "next/head";
import { useRouter } from "next/router";

import PropTypes from "prop-types";
// Components

/** ========== */

const Seo = ({ page, locationRouter }) => {
  const router = useRouter();
  const pageParseArr = JSON.parse(page);
  const getWordpressLink = (locationRouter) => {
    let link = "";

    switch (locationRouter) {
      case "hk":
        link = process.env.NEXT_PUBLIC_WORDPRESS_HK;
        break;
      case "offshore":
        link = process.env.NEXT_PUBLIC_WORDPRESS;
        break;
      case "sg":
        link = process.env.NEXT_PUBLIC_WORDPRESS_SG;
        break;
      case "vn":
        link = process.env.NEXT_PUBLIC_WORDPRESS_VN;
        break;
      default:
        link = process.env.NEXT_PUBLIC_WORDPRESS_HK;
        break;
    }

    return link;
  };

  const getFolder = () => {
    const link = router.asPath?.split("/");

    if (
      link[1] === "hk" ||
      link[1] === "offshore" ||
      link[1] === "sg" ||
      link[1] === "vn"
    ) {
      return `/${link[1]}`;
    }

    return "";
  };

  const headLink = () => {
    const lastUrl = router.asPath.substring(router.asPath.lastIndexOf("/") + 1);

    if (lastUrl === "contact-us" || lastUrl === "about-us") {
      return <></>;
    }

    return (
      <link
        rel="alternate"
        href={`${process.env.NEXT_PUBLIC_HOME_URL}${router.asPath}`}
        hrefLang="en"
      />
    );
  };
  const getCustomHead = ({ arr, isPublicSEO }) => {
    if (arr && arr.length > 0) {
      let cloneArr = arr;

      if (!isPublicSEO) {
        cloneArr = cloneArr.filter((item) => item.props.name != "robots");
      }
      const cloneArrToCustomString = JSON.stringify(cloneArr)
        .split(getWordpressLink(locationRouter))
        .join(process.env.NEXT_PUBLIC_HOME_URL + getFolder())
        .replace(/\n|\t/g, "");

      return {
        head: JSON.parse(cloneArrToCustomString),
      };
    }

    return {};
  };
  const dataSeo = getCustomHead({
    arr: pageParseArr,
    isPublicSEO: process.env.NEXT_PUBLIC_SEO == "true",
  });

  return (
    <Head>
      {process.env.NEXT_PUBLIC_SEO == "true" ? (
        <>
          {dataSeo?.head &&
            dataSeo.head.map((item, index) =>
              createElement(item?.type, {
                ...item?.props,
                key: index + 10,
              })
            )}
          {headLink()}
        </>
      ) : (
        <>
          {dataSeo?.head &&
            dataSeo.head.map((item, index) =>
              createElement(item?.type, { ...item?.props, key: index + 10 })
            )}
          <meta name="robots" content="noindex, nofollow" />
        </>
      )}
    </Head>
  );
};

export default Seo;

Seo.propTypes = {
  page: PropTypes.string,
  locationRouter: PropTypes.string,
};

Seo.defaultProps = {
  page: {},
};

/** ========== */
