import { useContext, useState } from "react";

import { useRouter } from "next/router";

import { LayoutContext } from "common/contexts";
import dataMenu from "common/data/menu/data.json";

import Img from "components/atoms/Img";
import LinkRouter from "components/atoms/LinkRouter";
import Dropdown from "components/common/Layout/Footer/Dropdow";

// DATA

const dataOffshoreService = dataMenu.dataOffshore.secretaries.concat(
  dataMenu.dataOffshore.rightServices
);
const dataFooter = {
  term_policy: [
    { title: "Terms of Service", href: "/terms-of-services", rel: "nofollow" },
    { title: "Legal Agreement", href: "/legal-of-agreement", rel: "nofollow" },
    { title: "Refund Policy", href: "/refund-policy", rel: "nofollow" },
    { title: "Privacy Policy", href: "/privacy-policy", rel: "nofollow" },
    {
      title: "Restricted Countries List",
      href: "/restricted-countries-list",
      rel: "nofollow",
    },
    { title: "FATCA Policy", href: "/fatca-policy", rel: "nofollow" },
  ],
  useful_links: [
    {
      title: "Become Our Partner",
      href: "/become-our-partner",
      rel: "nofollow",
    },
    { title: "Payment Guideline", href: "/payment-guideline", rel: "nofollow" },
    { title: "Make Payment", href: "/make-payment", rel: "nofollow" },
    { title: "Order Process", href: "/order-process", rel: "nofollow" },
    { title: "Whitelist Guide", href: "/whitelist-guide", rel: "nofollow" },
  ],
};

const LogoBrand = ({ subclass }) => {
  return (
    <div
      className={`row items-center justify-between lg:justify-start ${subclass}`}
    >
      <div>
        <div className="flex items-center">
          <div className="mr-2 ">
            {/* <Link href="https://twitter.com/bbcincorp"> */}
            <a
              href="https://twitter.com/bbcincorp"
              target="_blank"
              rel="nofollow noreferrer"
              className="flex items-center justify-center rounded  bg-bbc-white"
              style={{ width: "34px", height: "34px" }}
            >
              <Img
                src="/assets/images/layout/twitter-layout.svg"
                width={16}
                height={16}
                alt="twitter"
                loading="lazy"
              />
            </a>
            {/* </Link> */}
          </div>
          <div className="mr-2">
            <a
              target="_blank"
              rel="nofollow noreferrer"
              className="flex items-center justify-center rounded  bg-bbc-white"
              style={{ width: "34px", height: "34px" }}
              href="https://www.facebook.com/bbcincorp"
            >
              <Img
                src="/assets/images/layout/fb-layout.svg"
                width={8}
                height={16}
                alt="facebook"
                loading="lazy"
              />
            </a>
          </div>

          <div className="mr-2">
            <a
              target="_blank"
              rel="nofollow noreferrer"
              className="flex items-center justify-center rounded  bg-bbc-white"
              style={{ width: "34px", height: "34px" }}
              href="https://www.linkedin.com/company/bbcincorp"
            >
              <Img
                src="/assets/images/layout/linkedin-layout.svg"
                width={16}
                height={16}
                alt="linkedin"
                loading="lazy"
              />
            </a>
          </div>
          <div className="">
            <a
              target="_blank"
              rel="nofollow noreferrer"
              className="flex items-center justify-center rounded  bg-bbc-white"
              style={{ width: "34px", height: "34px" }}
              href="https://www.youtube.com/channel/UCm9z045jcWyhncuHoyYNbMw"
            >
              <Img
                src="/assets/images/layout/youtube-layout.svg"
                width={16}
                height={16}
                alt="youtube"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
/** ========== */
const Footer = () => {
  const [useful, setUseful] = useState(false);
  const [terms, setTerms] = useState(false);
  const [ourServices, setOurServices] = useState(false);
  const { locationRouter } = useContext(LayoutContext);
  const router = useRouter();
  const moment = new Date();
  const FilterData = () => {
    let data = null;

    if (locationRouter === "offshore") {
      data = dataOffshoreService;
      const indexPrivacyPolicy = dataFooter.term_policy.findIndex(
        (item) => item.href === "/privacy-policy"
      );

      dataFooter.term_policy[indexPrivacyPolicy].title = "GDPR Privacy Policy";
    } else if (locationRouter === "hk") {
      data = dataMenu.dataHongkong.services;
    } else if (locationRouter === "sg") {
      data = dataMenu.dataSingapore.services;
    } else if (locationRouter === "vn") {
      data = dataMenu.dataVietNam.services;
    }

    return data;
  };

  return (
    <div id="footer-01" className="relative z-20">
      <Img
        src="/assets/images/layout/footer-layout.png"
        alt="bg footer"
        width={1920}
        height={204}
        className=" -mb-0.5 h-full w-full object-cover object-center"
        wrapper={{
          className: "w-full",
        }}
      />
      <div className=" w-full bg-bbc-brand-900 pt-8">
        <div className="container">
          <div className="row justify-between pb-8">
            <div className="col-12 lg:col-6 ">
              <div className="flex justify-between pb-6">
                {locationRouter == "hk" ? (
                  <Img
                    src="/assets/images/layout/logo_white-hk-layout.svg"
                    width={138}
                    height={39}
                    alt="logo"
                    loading="lazy"
                  />
                ) : (
                  <Img
                    src="/assets/images/layout/logo_white-layout.svg"
                    width={138}
                    height={39}
                    alt="logo"
                    loading="lazy"
                  />
                )}
                <LogoBrand subclass="lg:hidden" />
              </div>
              {locationRouter !== "hk" && locationRouter !== "sg" && (
                <h4 className="block pb-2 text-lg font-semibold text-bbc-white hover:text-bbc-cyan-aqua">
                  <LinkRouter href="/about-us">About Us</LinkRouter>
                </h4>
              )}

              <h4 className="pb-4 text-lg font-semibold text-bbc-white ">
                {locationRouter === "sg" || locationRouter === "hk" ? (
                  <LinkRouter
                    className="hover:text-bbc-cyan-aqua"
                    href="/contact-us"
                  >
                    Contact Us
                  </LinkRouter>
                ) : (
                  "Contact Us"
                )}
              </h4>
              <ul className="mb-4 lg:mb-6">
                <li className="mb-2">
                  <a
                    href="mailto:service@bbcincorp.com"
                    rel="nofollow"
                    className="flex items-center text-sm   text-bbc-bombay hover:text-bbc-cyan-aqua"
                  >
                    <Img
                      src="/assets/icons/email.svg"
                      width={17}
                      height={17}
                      className="mr-2"
                      alt="DMCA.com Protection Status"
                      loading="lazy"
                    />
                    service@bbcincorp.com
                  </a>
                </li>
                {locationRouter !== "sg" && (
                  <li className="mb-2">
                    <a
                      href="tel:+85281928666"
                      rel="nofollow"
                      className="flex items-center text-sm  text-bbc-bombay hover:text-bbc-cyan-aqua"
                    >
                      <Img
                        src="/assets/icons/phone.svg"
                        width={17}
                        height={17}
                        className="mr-2"
                        alt="DMCA.com Protection Status"
                        loading="lazy"
                      />
                      (+852) 8192 8666
                    </a>
                  </li>
                )}
                {locationRouter !== "hk" && (
                  <li className="mb-2">
                    <a
                      href="tel:+6531381666"
                      rel="nofollow"
                      className="flex items-center text-sm   text-bbc-bombay hover:text-bbc-cyan-aqua"
                    >
                      <Img
                        src="/assets/icons/phone.svg"
                        width={17}
                        height={17}
                        className="mr-2"
                        alt="DMCA.com Protection Status"
                        loading="lazy"
                      />
                      (+65) 3138 1666
                    </a>
                  </li>
                )}
                {locationRouter !== "sg" && (
                  <li className="mb-2">
                    <a
                      rel="nofollow noreferrer"
                      target="_blank"
                      className=" flex text-sm   text-bbc-bombay hover:text-bbc-cyan-aqua"
                      href="https://goo.gl/maps/adod2kPo4Ht2GXXH9"
                    >
                      <div className="mt-1">
                        <Img
                          src="/assets/icons/location.svg"
                          width={17}
                          height={17}
                          wrapper={{
                            className: "inline-block mr-2 mt-0.5 ",
                            style: { width: "17px", height: "17px" },
                          }}
                          alt="DMCA.com Protection Status"
                          loading="lazy"
                        />
                      </div>
                      Room 9, 4F, Beverley Commercial Centre, 87-105 Chatham
                      road South, Tsim Sha Tsui, Kowloon, Hong Kong
                    </a>
                  </li>
                )}
                {locationRouter !== "hk" && (
                  <li>
                    <div className="flex  text-sm  text-bbc-bombay ">
                      <div className="mt-0.5 text-sm xl:mt-0 ">
                        <Img
                          src="/assets/icons/location.svg"
                          width={17}
                          height={17}
                          wrapper={{
                            className: "inline-block mr-2 mt-0.5 ",
                            style: { width: "17px", height: "17px" },
                          }}
                          alt="DMCA.com Protection Status"
                          loading="lazy"
                        />
                      </div>
                      68 Circular road, #02-01 Singapore (049422), Singapore
                    </div>
                  </li>
                )}
              </ul>
              {/** Mobile */}
              <div className="col-12 block lg:hidden">
                <Dropdown
                  title="Our Services"
                  classNameTitle=" text-bbc-white text-lg leading-7 font-semibold "
                  state={ourServices}
                  onClick={() => setOurServices(!ourServices)}
                  content={
                    <ul className="mb-4">
                      {FilterData().map((item, index) => (
                        <li key={index} className="pb-2 last:pb-0">
                          <LinkRouter
                            href={item?.href}
                            className="text-sm text-bbc-bombay  "
                          >
                            {item?.title}
                          </LinkRouter>
                        </li>
                      ))}
                    </ul>
                  }
                />
                <Dropdown
                  title="Useful Links"
                  classNameTitle="text-lg text-bbc-white leading-7 font-semibold "
                  state={useful}
                  onClick={() => setUseful(!useful)}
                  content={
                    <ul className="mb-4">
                      {dataFooter.useful_links.map((item, index) => (
                        <li key={index} className="pb-2 last:pb-0">
                          <LinkRouter
                            href={item?.href}
                            className="text-sm text-bbc-bombay"
                            rel={item?.rel}
                            target={item?.target}
                          >
                            {item?.title}
                          </LinkRouter>
                        </li>
                      ))}
                    </ul>
                  }
                />
                <Dropdown
                  title="Terms & Policy"
                  classNameTitle="text-lg text-bbc-white leading-7 font-semibold "
                  state={terms}
                  onClick={() => setTerms(!terms)}
                  content={
                    <ul className="mb-4">
                      {dataFooter.term_policy.map((item, index) => (
                        <li key={index} className="pb-2 last:pb-0">
                          <LinkRouter
                            href={item?.href}
                            className="text-sm text-bbc-bombay"
                            rel={item?.rel}
                            target={item?.target}
                          >
                            {item?.title}
                          </LinkRouter>
                        </li>
                      ))}
                    </ul>
                  }
                />
              </div>
              <div
                className={`${
                  locationRouter === "sg" || locationRouter === "hk"
                    ? "mb-8"
                    : ""
                } mt-6 flex`}
              >
                <a
                  className="mr-5 block max-h-20 lg:mr-8"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="DMCA.com Protection Status"
                  href={`//www.dmca.com/Protection/Status.aspx?ID=1db12910-cb8b-41ab-9646-015b91ecb2c9&refurl=${
                    process.env.NEXT_PUBLIC_HOME_URL
                  }${router.asPath === "/" ? "" : router.asPath}`}
                >
                  <Img
                    src="/assets/images/layout/dmca-layout.svg"
                    width={160}
                    height={80}
                    alt="DMCA.com Protection Status"
                    loading="lazy"
                  />
                </a>
                <div className="grid grid-cols-3 gap-1 lg:gap-2 ">
                  <Img
                    src="/assets/images/layout/visa-layout.svg"
                    width={64}
                    height={36}
                    alt="visa"
                    className="max-h-9"
                    loading="lazy"
                  />

                  <Img
                    src="/assets/images/layout/mastercard-layout.svg"
                    width={64}
                    height={36}
                    className="max-h-9"
                    alt="master-card"
                    loading="lazy"
                  />

                  <Img
                    src="/assets/images/layout/american_express-layout.svg"
                    width={64}
                    height={36}
                    className="max-h-9"
                    alt="american-express"
                    loading="lazy"
                  />

                  <Img
                    src="/assets/images/layout/jcb-layout.svg"
                    width={64}
                    height={36}
                    className="max-h-9"
                    alt="jcb"
                    loading="lazy"
                  />

                  <Img
                    src="/assets/images/layout/discover-layout.svg"
                    width={64}
                    height={36}
                    className="max-h-9"
                    alt="discover"
                    loading="lazy"
                  />

                  <Img
                    src="/assets/images/layout/stripe-layout.svg"
                    width={64}
                    height={36}
                    className="max-h-9"
                    alt="stripe"
                    loading="lazy"
                  />
                </div>
              </div>
              {locationRouter === "hk" && (
                <div className="border-t border-white pt-8 text-center  opacity-75 lg:text-left  ">
                  <p className=" pb-4 text-sm leading-6 text-bbc-bombay">
                    BBCIncorp Limited, Hong Kong - Registration number: 2508698
                    and D-U-N-S number: 664611652.
                  </p>
                  <p className=" pb-4 text-sm leading-6 text-bbc-bombay">
                    Licensed to use trademark by the trademark owner - BBCincorp
                    Pte. Ltd.
                  </p>
                  <div className="text-center text-sm text-bbc-bombay lg:text-left">
                    {moment.getFullYear()} © BBCIncorp Limited - Copyright All
                    Rights Reserved
                  </div>
                </div>
              )}
              {locationRouter === "sg" && (
                <div className="border-t border-white pt-8 text-center   opacity-75 lg:text-left  ">
                  <p className=" pb-4 text-sm leading-6 text-bbc-bombay">
                    BBCIncorp Pte. Ltd., Singapore - Registration number:
                    201920843M
                  </p>
                  <div className="text-center text-sm  text-bbc-bombay lg:text-left">
                    {moment.getFullYear()} © BBCIncorp Pte. Ltd. - Copyright All
                    Rights Reserved
                  </div>
                </div>
              )}
            </div>
            {/* //col-3 */}
            <div className="col-3  hidden lg:block">
              <div className="">
                <h4 className="pb-4 text-lg font-semibold text-bbc-white">
                  Our Services
                </h4>
                <ul className="mb-6">
                  {FilterData().map((item, index) => (
                    <li key={index} className="pb-2 last:pb-0 ">
                      <LinkRouter
                        href={item?.href}
                        rel={item?.rel}
                        target={item?.target}
                        className="text-sm capitalize text-bbc-bombay hover:text-bbc-cyan-aqua"
                      >
                        {item?.title}
                      </LinkRouter>
                    </li>
                  ))}
                </ul>
                <h4 className="pb-4 text-lg font-semibold text-bbc-white">
                  Useful Links
                </h4>
                <ul>
                  {dataFooter.useful_links.map((item, index) => (
                    <li key={index} className="pb-2 last:pb-0">
                      <LinkRouter
                        href={item?.href}
                        rel={item?.rel}
                        target={item?.target}
                        className="text-sm text-bbc-bombay hover:text-bbc-cyan-aqua"
                      >
                        {item?.title}
                      </LinkRouter>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* //col-3 */}
            <div className="col-12 lg:col-3 ">
              <div className="mb-6 hidden lg:block">
                <h4 className="pb-4 text-lg  font-semibold text-bbc-white">
                  Terms & Policy
                </h4>
                <ul className="mb-6">
                  {dataFooter.term_policy.map((item, index) => (
                    <li key={index} className="pb-2 last:pb-0">
                      <LinkRouter
                        href={item?.href}
                        rel={item?.rel}
                        target={item?.target}
                        className="text-sm text-bbc-bombay hover:text-bbc-cyan-aqua"
                      >
                        {item?.title}
                      </LinkRouter>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="lg:w-fit">
                <div className="hidden lg:block">
                  <LogoBrand />
                </div>
                {locationRouter === "sg" && (
                  <div className="mt-4 flex items-center justify-center lg:mt-6">
                    <div id="duns-seal"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {locationRouter === "offshore" || locationRouter === "vn" ? (
            <div className="row">
              <div className="col-12">
                {locationRouter === "offshore" && (
                  <div className="border-t border-white py-8 text-center opacity-75  ">
                    <p className=" pb-2 text-sm leading-6 text-bbc-bombay">
                      BBCIncorp Limited, Hong Kong - Registration number:
                      2508698 and D-U-N-S number: 664611652
                    </p>
                    <div className="text-center text-sm text-bbc-bombay">
                      {moment.getFullYear()} © BBCIncorp Limited - Copyright All
                      Rights Reserved
                    </div>
                  </div>
                )}
                {locationRouter === "vn" && (
                  <div className="border-t border-white py-8 text-center opacity-75  ">
                    <p className=" pb-2 text-sm leading-6 text-bbc-bombay">
                      BBCIncorp JSC, Vietnam - Registration number: 0317232747
                    </p>
                    <div className="text-center text-sm text-bbc-bombay">
                      {moment.getFullYear()} © BBCIncorp JSC - Copyright All
                      Rights Reserved
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Footer;
