import { countries, TAX_RATE_TYPES } from "common/countries";

import LinkRouter from "components/atoms/LinkRouter";
// ZeroTax

const ZeroTax = () => {
  return (
    <div className="border-b border-[#B5B5C3] pb-[22px]">
      <h4 className="pb-6 text-xl font-semibold uppercase text-bbc-brand-800">
        Zero Tax
      </h4>
      <ul className="row">
        {countries
          .filter((country) => country.taxRate === TAX_RATE_TYPES.ZERO)
          .sort((number1, number2) =>
            number1.zeroCount > number2.zeroCount ? 1 : -1
          )
          .map((country, index) => (
            <li key={index} className="col-4  pb-2 text-bbc-black last:pb-0">
              <LinkRouter
                href={country.href}
                className="text-nowrap hover:text-bbc-primary"
              >
                {country.label}
              </LinkRouter>
            </li>
          ))}
      </ul>
    </div>
  );
};

// LowTax

const LowTax = () => {
  return (
    <div className="pt-6">
      <h4 className="pb-6 text-xl font-semibold uppercase text-bbc-brand-800">
        LOW TAX
      </h4>
      <ul className="row">
        {countries
          .filter((country) => country.taxRate === TAX_RATE_TYPES.LOW)
          .sort((a, b) => (a.label > b.label ? 1 : -1))
          .map((country, index) => (
            <li key={index} className="col-4  text-bbc-black ">
              <LinkRouter
                href={country.href}
                className="hover:text-bbc-primary"
              >
                {country.label}
              </LinkRouter>
            </li>
          ))}
      </ul>
    </div>
  );
};

// TaxRatePanel

export default function TaxRatePanel() {
  return (
    <div>
      <div className="col-12 ">
        <div>
          <ZeroTax />
        </div>
      </div>
      <div className="col-12">
        <LowTax />
      </div>
    </div>
  );
}
