import { Fragment, useContext, useRef, useState } from "react";

import { useRouter } from "next/router";

import { Listbox, Transition } from "@headlessui/react";

import { LayoutContext } from "common/contexts";
import dataMenu from "common/data/menu/data.json";
import useOnClickOutside from "common/hooks/useOnClickOutside";

import { FaSearch } from "components/atoms/Icon/fa/FaSearch";
import { FaTimes } from "components/atoms/Icon/fa/FaTimes";
import { FiChevronDown } from "components/atoms/Icon/fi/FiChevronDown";
import Img from "components/atoms/Img";
import LinkRouter from "components/atoms/LinkRouter";

import { ChangePriceSiteList } from "../index";

import MegaMenuCountries from "./MegaMenuMobile/MegaMenuCountries";
import MegaMenuResources from "./MegaMenuMobile/MegaMenuResources/index";
import MegaMenuServices from "./MegaMenuMobile/MegaMenuServices/index";

import styles from "./Index.module.scss";

export default function MobileNavbar({ locationRouter }) {
  const { unit, currencyUnit, setCurrencyUnit } = useContext(LayoutContext);

  const router = useRouter();
  const [menuOpened, setMenuOpened] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, setMenuOpened);

  const LocationInitial = dataMenu?.dataLanguage?.filter(
    (item) => item.location == locationRouter
  );
  const [selected, setSelected] = useState(LocationInitial[0]);
  const [isOpenSearchBox, setIsOpenSearchBox] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const handleSearchChange = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setSearchKey("");
      setMenuOpened(false);
      setIsOpenSearchBox(false);
      router.push(`/${locationRouter}/search?key=${event.target.value}`);
    } else {
      setSearchKey(event.target.value);
    }
  };

  const onClickSearch = () => {
    setMenuOpened(false);
    setIsOpenSearchBox(false);
    router.push(`/${locationRouter}/search?key=${searchKey}`);
  };

  const renderMobileSearchBox = () => {
    return (
      <div
        className={`${
          isOpenSearchBox ? ` ${styles.openSearch}  ` : "top-0 block "
        } relative flex w-full items-center justify-end transition-all duration-500 ease-in-out`}
      >
        {/* input */}
        <div
          className={` flex items-center rounded-full border border-bbc-primary bg-bbc-white py-2 px-4 ${
            isOpenSearchBox === true ? " w-9/12 " : "w-full"
          } z-20  transition-all duration-500 ease-in-out`}
          onFocus={() => {
            setIsOpenSearchBox(true);
          }}
        >
          <FaSearch
            size={18}
            className=" font-semibold text-bbc-neutral-600"
            onClick={onClickSearch}
          />
          <style>{`
            /* Remove chrome clear button */
            input[type="search"]::-webkit-search-decoration,
            input[type="search"]::-webkit-search-cancel-button,
            input[type="search"]::-webkit-search-results-button,
            input[type="search"]::-webkit-search-results-decoration {
              -webkit-appearance: none;
            }
          `}</style>
          <input
            type="search"
            placeholder="Search"
            value={searchKey}
            className={
              "w-full border-none bg-transparent p-0 pl-2 font-normal placeholder-bbc-neutral-700 outline-none focus:ring-0"
            }
            onChange={handleSearchChange}
            onKeyDown={handleSearchChange}
          />
          {isOpenSearchBox && (
            <FaTimes
              size={18}
              className="font-semibold text-bbc-neutral-600"
              onClick={() => {
                setSearchKey("");
              }}
            />
          )}
        </div>
        {/* Cancel */}
        {isOpenSearchBox && (
          <button
            className={`${
              isOpenSearchBox ? "w-3/12" : "w-full"
            } z-10 border-none  bg-transparent text-sm font-medium text-white outline-none transition-all duration-500 ease-in-out  hover:text-[#2997ff] focus:ring-0`}
            onClick={() => {
              setIsOpenSearchBox(false);
            }}
          >
            Cancel
          </button>
        )}
      </div>
    );
  };

  return (
    <nav
      className="shadowMenu fixed top-0 left-0 z-50 block   w-full overflow-y-auto overflow-x-hidden bg-white p-0  text-bbc-black lg:hidden"
      ref={ref}
      style={{ maxHeight: "90%" }}
    >
      <div className="container">
        <div className="relative">
          <div className="flex  items-center justify-between  py-4">
            <LinkRouter
              href="/"
              className="block h-9  cursor-pointer"
              style={{ width: locationRouter == "hk" ? "136px" : "130px" }}
            >
              {locationRouter == "hk" ? (
                <Img
                  width={136}
                  height={37}
                  src="assets/images/layout/logo_black-hk-layout.svg"
                  className="logo webpexpress-processed"
                  alt="logo"
                  loading="eager"
                />
              ) : (
                <Img
                  width={130}
                  height={36}
                  src="assets/images/layout/logo_black-layout.svg"
                  className="logo webpexpress-processed"
                  alt="logo"
                  loading="eager"
                />
              )}
            </LinkRouter>
            <button
              onClick={() => setMenuOpened(!menuOpened)}
              className="flex h-7 cursor-pointer flex-col justify-around border-0 bg-transparent p-0 outline-none focus:outline-none"
            >
              <span
                style={{ transformOrigin: "1px" }}
                className={`block h-0.5 w-7 rounded-sm bg-bbc-brand-800 ${
                  menuOpened
                    ? "rotate-45 transform transition-all duration-150 ease-linear "
                    : ""
                }`}
              />
              <span
                style={{ transformOrigin: "1px" }}
                className={`block h-0.5 w-7 rounded-sm  bg-bbc-brand-800 ${
                  menuOpened
                    ? "translate-x-2  transform opacity-0 transition-all duration-150 ease-linear "
                    : ""
                }`}
              />
              <span
                style={{ transformOrigin: "1px" }}
                className={`block h-0.5 w-7 rounded-sm bg-bbc-brand-800  ${
                  menuOpened
                    ? "-rotate-45 transform transition-all duration-150 ease-linear"
                    : ""
                }`}
              />
            </button>
          </div>
          <Transition
            show={menuOpened}
            enter="transition-all duration-300 ease-linear"
            enterFrom="transform max-h-0 "
            enterTo="transform max-h-[90vh] opacity-100"
            leave="transition-all duration-200 ease-linear"
            leaveFrom="transform max-h-[90vh]  opacity-50"
            leaveTo="transform max-h-0 "
          >
            <div>
              <div className="mb-8 mt-3">
                <div className={"row mb-4"}>
                  <div
                    className={`${
                      locationRouter == "sg" &&
                      ChangePriceSiteList.includes(router.asPath)
                        ? "col-8"
                        : "col-12"
                    }`}
                  >
                    <Listbox value={selected} onChange={setSelected}>
                      {({ open }) => (
                        <div className=" rounded-full border border-bbc-primary ">
                          <Listbox.Button className="w-full outline-none focus:outline-none ">
                            <div className="flex items-center justify-between py-2 px-4">
                              <div className="flex items-center">
                                <Img
                                  src={
                                    selected?.src ||
                                    "/assets/images/layout/flat-offshore-layout.png"
                                  }
                                  width={selected?.width || 24}
                                  height={selected?.height || 24}
                                  className="w-full "
                                  wrapper={{
                                    className: "w-6 h-6",
                                  }}
                                  alt={selected?.name}
                                />
                                <p className="ml-2 font-medium text-bbc-black">
                                  {selected?.name}
                                </p>
                              </div>
                              <div
                                className={`${
                                  open ? "rotate-180" : "rotate-0 "
                                }   text-bbc-black transition-all duration-75 ease-linear`}
                              >
                                <FiChevronDown aria-hidden="true" />
                              </div>
                            </div>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-50 mt-4 w-full rounded-2xl  bg-white p-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {dataMenu?.dataLanguage
                                ?.filter(
                                  (item) => item.location !== locationRouter
                                )
                                .map((countries, index) => {
                                  return (
                                    <Listbox.Option
                                      key={index}
                                      className={({ active }) =>
                                        ` relative cursor-default select-none border-b border-[#B5B5C3] py-3 first:pt-0 last:border-b-0 last:pb-0   ${
                                          active ? "" : ""
                                        }`
                                      }
                                      value={countries}
                                    >
                                      <a
                                        href={countries.href}
                                        className="flex cursor-pointer items-center "
                                      >
                                        <div>
                                          <Img
                                            src={countries?.src}
                                            width={24}
                                            height={24}
                                            className="h-full w-full"
                                            wrapper={{ className: "w-6 h-6" }}
                                            alt={countries?.name}
                                          />
                                        </div>

                                        <p className=" ml-2 text-base font-medium hover:text-bbc-primary">
                                          {countries.name}
                                        </p>
                                      </a>
                                    </Listbox.Option>
                                  );
                                })}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      )}
                    </Listbox>
                  </div>
                  {locationRouter == "sg" &&
                    ChangePriceSiteList.includes(router.asPath) && (
                      <div className="col-4 pl-0">
                        <Listbox
                          value={currencyUnit}
                          onChange={(value) => {
                            setCurrencyUnit((prev) => {
                              return {
                                previous: prev.id,
                                current: value.id,
                                ...value,
                              };
                            });
                          }}
                        >
                          {({ listUnitOptions }) => (
                            <div className=" rounded-full border border-bbc-primary ">
                              <Listbox.Button className="w-full outline-none focus:outline-none ">
                                <div className="flex items-center justify-between py-2 px-4">
                                  <div className="flex items-center">
                                    {/* <div className="grid h-6 w-6 place-content-center rounded-full bg-bbc-yellow-500 text-[10px] font-semibold leading-6">
                                    {currencyUnit?.value}
                                  </div> */}
                                    <p className="ml-2 font-medium text-bbc-black">
                                      {currencyUnit?.name}
                                    </p>
                                  </div>
                                  <div
                                    className={`${
                                      listUnitOptions
                                        ? "rotate-180"
                                        : "rotate-0 "
                                    }   text-bbc-black transition-all duration-75 ease-linear`}
                                  >
                                    <FiChevronDown aria-hidden="true" />
                                  </div>
                                </div>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-50 mt-4 w-full max-w-[110px] rounded-2xl bg-white p-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {unit
                                    ?.filter(
                                      (item) => item.name !== currencyUnit.name
                                    )
                                    .map((el, index) => {
                                      return (
                                        <Listbox.Option
                                          key={index}
                                          className={({ active }) =>
                                            ` relative cursor-default select-none border-b border-[#B5B5C3] py-3 first:pt-0 last:border-b-0 last:pb-0   ${
                                              active ? "" : ""
                                            }`
                                          }
                                          value={el}
                                        >
                                          <div className="flex cursor-pointer items-center ">
                                            {/* <div className="grid h-6 w-6 place-content-center rounded-full bg-bbc-yellow-500 text-[10px] font-semibold leading-6">
                                            {el?.value}
                                          </div> */}
                                            <p className=" ml-2 text-base font-medium hover:text-bbc-primary">
                                              {el.name}
                                            </p>
                                          </div>
                                        </Listbox.Option>
                                      );
                                    })}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          )}
                        </Listbox>
                      </div>
                    )}
                </div>

                <div className="col-12 ">
                  {renderMobileSearchBox()}
                  <Transition appear show={isOpenSearchBox} as={Fragment}>
                    <div className="relative h-screen">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="fixed inset-0 w-full bg-bbc-gray"></div>
                      </Transition.Child>
                    </div>
                  </Transition>
                </div>
              </div>
              <ul>
                <li>
                  <MegaMenuServices locationRouter={locationRouter} />
                </li>
                {locationRouter === "offshore" && (
                  <li>
                    <MegaMenuCountries />
                  </li>
                )}
                <li>
                  <MegaMenuResources locationRouter={locationRouter} />
                </li>
                <li className=" pb-4 font-medium">
                  <LinkRouter rel="dofollow" href="/become-our-partner">
                    Partner
                  </LinkRouter>
                </li>
                {locationRouter === "offshore" || locationRouter === "vn" ? (
                  <li className="pb-10 font-medium">
                    <LinkRouter
                      className=" inline-block"
                      rel="dofollow"
                      href="/contact-us"
                    >
                      Contact Us
                    </LinkRouter>
                  </li>
                ) : (
                  ""
                )}
                {locationRouter === "sg" || locationRouter === "hk" ? (
                  <li className="pb-10 font-medium">
                    <LinkRouter
                      className="inline-block"
                      rel="dofollow"
                      href="/about-us"
                    >
                      About Us
                    </LinkRouter>
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <div>
                    <a
                      href="/onboarding/register"
                      className="mr-4 inline-block w-full rounded-full bg-bbc-primary py-2 text-center font-semibold text-bbc-white transition-all hover:bg-bbc-brand-600"
                    >
                      Set Up Now
                    </a>
                  </div>
                  <div className="mt-6 mb-6">
                    <a
                      href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}`}
                      className="  mr-4  inline-block w-full rounded-full border border-bbc-primary bg-bbc-white py-2 text-center font-semibold   text-bbc-primary  transition-all hover:bg-bbc-brand-600  "
                    >
                      Login
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </Transition>
        </div>
      </div>
    </nav>
  );
}
