import { useEffect } from "react";

/**
 * Set click outside menu by customized hook for header.
 * @param {reference} refNav connect jsx navbar reference.
 * @param {reference} refMenu connect jsx menu reference.
 * @param {function} handler set the new state to close the menu.
 */
const useOnHeaderClickOutside = (refNav, refMenu, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!refNav.current || refNav.current.contains(event.target)) {
        return;
      }
      if (!refMenu.current || refMenu.current.contains(event.target)) {
        return;
      }
      handler(null);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      /* Clean up function. Remove event before component unmount. */
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [refNav, refMenu, handler]);
};

export default useOnHeaderClickOutside;
