import { HiOutlineChevronDoubleRight } from "components/atoms/Icon/hi/HiOutlineChevronDoubleRight";
import Image from "components/atoms/Img";
import Link from "components/atoms/LinkRouter";
const CardColum = ({ imageSrc, title, description, href, border, subLink }) => {
  return (
    <div className={" max-w-[100%] cursor-pointer overflow-x-hidden"}>
      <div className="group">
        <Link href={href} rel="dofollow">
          <div className="flex">
            <Image
              src={imageSrc}
              width={36}
              height={36}
              alt={title}
              className="mr-4"
            />
            <div className={subLink ? "" : border}>
              <div className="flex items-center ">
                <h4
                  className="inline-block pt-1 pb-1 text-lg  font-semibold uppercase tracking-[0.25px] text-bbc-brand-800 
              group-hover:text-bbc-primary group-hover:transition-all group-hover:duration-75 group-hover:ease-linear "
                >
                  {title}
                </h4>
                <div className="hidden group-hover:block ">
                  <HiOutlineChevronDoubleRight
                    size={16}
                    className={` ${
                      title == "Company Formation" ? "ml-1" : "ml-2"
                    } text-bbc-primary  transition-all duration-75 ease-linear`}
                  />
                </div>
              </div>
              <p className="text-sm tracking-[0.25px]  text-bbc-neutral-800 group-hover:text-bbc-black xl:text-base">
                {description}
              </p>
            </div>
          </div>
        </Link>
      </div>
      {subLink && (
        <div
          className={`mt-4 ml-[50px] flex flex-col ${subLink ? border : ""}`}
        >
          {subLink.map((el, idx) => (
            <Link
              key={idx}
              href={el.link}
              className="py-1 font-semibold text-bbc-brand-800 hover:text-bbc-primary"
            >
              {el.name}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
const CardRow = ({ imageSrc, title, description, href }) => {
  return (
    <div className="group cursor-pointer ">
      <Link href={href} rel="dofollow">
        <Image
          src={imageSrc}
          width={36}
          height={36}
          alt={title}
          className="mb-3 xl:mb-4 "
          wrapper={{ className: "block" }}
        />

        <div className="flex items-center pb-4 xl:pb-6 ">
          <h4
            className="inline-block text-base font-semibold uppercase tracking-[0.25px] text-bbc-brand-800 group-hover:text-bbc-primary 
              group-hover:transition-all group-hover:duration-75 group-hover:ease-linear xl:text-xl "
          >
            {title}
          </h4>

          <div className="hidden group-hover:block">
            <HiOutlineChevronDoubleRight
              size={16}
              className={` ${
                title == "Company Formation" ? "ml-1" : "ml-2"
              } text-bbc-primary  transition-all duration-75 ease-linear`}
            />
          </div>
        </div>

        <p className="text-sm tracking-[0.25px]  text-bbc-neutral-800 group-hover:text-bbc-black xl:text-base">
          {description}
        </p>
      </Link>
    </div>
  );
};

export default function MegaItem({ className, data }) {
  return (
    <>
      {Array.isArray(data) && data.length > 4 ? (
        <div className="row">
          <div
            className={`shadowMenu col-10  m-auto  mt-5 justify-between rounded-[10px] bg-bbc-white py-[30px] ${className}`}
          >
            {data &&
              data.map((item, index) => {
                return (
                  <div className="col  pt-4 first:pt-0 last:pb-0" key={index}>
                    <CardColum
                      imageSrc={item.src}
                      title={item.title}
                      description={item.description}
                      href={item.href}
                      border={`${
                        index == data.length - 1
                          ? ""
                          : "border-b border-bbc-silver w-full pb-4 "
                      }`}
                      subLink={item.subLink}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div
          className={`shadowMenu row  mt-5 justify-between rounded-[10px] bg-bbc-white py-[30px] ${className}`}
        >
          {data &&
            data.map((item, index) => {
              return (
                <div
                  className="col border-r border-bbc-silver last:border-0 "
                  key={index}
                >
                  <CardRow
                    imageSrc={item.src}
                    title={item.title}
                    description={item.description}
                    href={item.href}
                  />
                </div>
              );
            })}
        </div>
      )}
    </>
  );
}
