import { useContext, useEffect, useRef, useState } from "react";

import { LayoutContext } from "common/contexts";
import useOnHeaderClickOutside from "common/hooks/useOnHeaderClickOutside";

import { FaChevronDown } from "components/atoms/Icon/fa/FaChevronDown";
import Img from "components/atoms/Img";
import LinkRouter from "components/atoms/LinkRouter";

import MegaMenuDesktop from "./MegaMenuDesktop";

const useCustomEffect = (
  refNav,
  refMenu,
  handler,
  active,
  navId,
  navPanelId
) => {
  const { navLight } = useContext(LayoutContext);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleNavbar = () => {
        const currentNav = document.getElementById(navId);
        const currNavPanel = document.getElementById(navPanelId);

        if (currentNav) {
          if (
            document.body.scrollTop > 0 ||
            document.documentElement.scrollTop > 0
          ) {
            /*
             * If (!currentNav.classList.contains("fixed")) {
             *   currentNav.classList.add("fixed");
             * }
             */
            currentNav.style.top = `${
              38 - document.documentElement.scrollTop >= 0
                ? 38 - document.documentElement.scrollTop
                : 37
            }px`;
          } else if (currentNav.classList.contains("fixed")) {
            currentNav.classList.remove("fixed");
          }
          if (
            document.body.scrollTop > 38 ||
            document.documentElement.scrollTop > 38 ||
            navLight == true
          ) {
            currNavPanel.style.boxShadow = "0 0 15px 0 rgb(119 119 119 / 30%)";
            active(true);
          } else {
            currNavPanel.style.boxShadow = "0px 0px 0px 0px";
            active(false);
          }
        }
      };

      handleNavbar();
      window.addEventListener("scroll", handleNavbar);

      return () => {
        window.removeEventListener("scroll", handleNavbar);
      };
    }
  }, [active, navId, navLight, navPanelId]);

  useOnHeaderClickOutside(refNav, refMenu, handler);
};

export default function MenuDesktop({ locationRouter, customMenu }) {
  const [tab, setTab] = useState(null);
  const [tabActive, setTabActive] = useState(false);
  const refNav = useRef();
  const refMenu = useRef();

  useCustomEffect(
    refNav,
    refMenu,
    setTab,
    setTabActive,
    "default-navbar-01",
    "default-navbar-panel-01"
  );
  const handleTab = (newTab) => {
    setTab(tab === newTab ? null : newTab);
  };

  return (
    <>
      <nav
        id="default-navbar-01"
        className="fixed  z-50 hidden h-auto w-full text-bbc-black  lg:block"
        style={{ top: "38px" }}
      >
        <div
          id="default-navbar-panel-01"
          className={`flex items-center py-3 transition-all duration-150 ${
            tab !== null || tabActive || customMenu ? " bg-white" : ""
          } `}
          style={{ height: "66px" }}
          ref={refNav}
        >
          <div className="container">
            <div className=" row items-center">
              <div className="lg:col-auto xl:col-2 ">
                {" "}
                <a
                  href={`/${locationRouter}`}
                  className="block h-9 cursor-pointer"
                  style={{ width: locationRouter == "hk" ? "136px" : "130px" }}
                >
                  {locationRouter == "hk" ? (
                    <Img
                      width={136}
                      height={37}
                      src="assets/images/layout/logo_black-hk-layout.svg"
                      wrapper={{
                        className: "block",
                      }}
                      className="h-full w-full"
                      alt="logo"
                      loading="eager"
                    />
                  ) : (
                    <Img
                      width={130}
                      height={36}
                      src="assets/images/layout/logo_black-layout.svg"
                      wrapper={{
                        className: "block",
                      }}
                      className="h-full w-full"
                      alt="logo"
                      loading="eager"
                    />
                  )}
                </a>
              </div>
              <div className="lg:col-auto  lg:px-2  xl:col-7">
                <ul className=" flex justify-start text-bbc-brand-900">
                  <li
                    className={`text-hover-transform-x flex cursor-pointer items-center justify-center text-bbc-brand-900 hover:text-bbc-primary lg:mr-4 xl:mr-10 ${
                      tab === 0 ? " hover:text-bbc-primary" : ""
                    }`}
                    onClick={() => handleTab(0)}
                    title="Services"
                  >
                    <span className=" mr-2 select-none font-medium ">
                      Services
                    </span>
                    <div
                      className={`  ${
                        tab === 0 ? "rotate-180" : "rotate-0 "
                      }  transition-all duration-150 ease-linear`}
                    >
                      <FaChevronDown size={10} />
                    </div>
                  </li>
                  {locationRouter === "offshore" && (
                    <li
                      className={`text-hover-transform-x flex cursor-pointer items-center justify-center text-bbc-brand-900 hover:text-bbc-primary lg:mr-4 xl:mr-10
                        ${tab === 1 ? " hover:text-bbc-primary" : ""}`}
                      onClick={() => handleTab(1)}
                      title="Countries"
                    >
                      <span className="  mr-2 select-none font-medium ">
                        Countries
                      </span>
                      <div
                        className={`${
                          tab === 1 ? "rotate-180" : "rotate-0 "
                        } text-xs transition-all duration-150 ease-linear`}
                      >
                        {" "}
                        <FaChevronDown size={10} />
                      </div>
                    </li>
                  )}
                  <li
                    className={`text-hover-transform-x flex cursor-pointer items-center justify-center text-bbc-brand-900 hover:text-bbc-primary lg:mr-4 xl:mr-10 ${
                      tab === 2 ? " hover:text-bbc-primary" : ""
                    }`}
                    onClick={() => handleTab(2)}
                    title="Resources"
                  >
                    <span className=" mr-2 select-none font-medium ">
                      Resources
                    </span>
                    <div
                      className={`${
                        tab === 2 ? "rotate-180" : "rotate-0 "
                      } text-xs transition-all duration-150 ease-linear`}
                    >
                      <FaChevronDown size={10} />
                    </div>
                  </li>

                  <li className="text-hover-transform-x md:mr-4 xl:mr-[30px] ">
                    <LinkRouter
                      href="/become-our-partner"
                      rel="dofollow"
                      title="Partner"
                      className=" inline-block select-none  font-medium text-bbc-brand-900 hover:text-bbc-primary "
                    >
                      Partner
                    </LinkRouter>
                  </li>
                  {locationRouter === "offshore" || locationRouter === "vn" ? (
                    <li className="text-hover-transform-x">
                      <LinkRouter
                        href="/contact-us"
                        rel="dofollow"
                        title="Contact Us"
                        className=" text-decoration-none inline-block  select-none  font-medium text-bbc-brand-900 hover:text-bbc-primary "
                      >
                        Contact Us
                      </LinkRouter>
                    </li>
                  ) : (
                    ""
                  )}
                  {locationRouter === "sg" || locationRouter === "hk" ? (
                    <li className="text-hover-transform-x">
                      <LinkRouter
                        href="/about-us"
                        className=" text-decoration-none  inline-block select-none  font-medium text-bbc-brand-900 hover:text-bbc-primary "
                      >
                        About Us
                      </LinkRouter>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              <div className="lg:col-auto xl:col-3">
                <div className="flex items-center justify-end">
                  <a
                    href="/onboarding/register"
                    className={`inline-block  rounded-full  py-2 px-6 font-semibold 
                      transition-all  lg:mr-3 xl:mr-4
                    ${
                      tabActive || tab !== null || customMenu
                        ? " bg-bbc-primary text-white hover:bg-bbc-secondary-100 hover:text-white"
                        : "bg-bbc-white text-bbc-primary hover:bg-bbc-brand-600 hover:text-bbc-white "
                    }`}
                  >
                    Set Up Now
                  </a>

                  <a
                    href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}`}
                    className=" rounded-full  border border-bbc-primary bg-transparent py-[7px] px-6 font-semibold text-bbc-primary transition-all hover:border-bbc-secondary-100  hover:bg-bbc-secondary-100 hover:text-bbc-white"
                  >
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MegaMenuDesktop
          locationRouter={locationRouter}
          tab={tab}
          forwardedRef={refMenu}
          id="menu-01"
        />
      </nav>
    </>
  );
}
