import { useState } from "react";

import { countries, TAX_RATE_TYPES } from "common/countries";

import LinkRouter from "components/atoms/LinkRouter";
import Dropdown from "components/common/Layout/Header/MenuComponents/Dropdow";

const ZeroTaxAccordion = () => {
  const [zeroOpened, setZeroOpened] = useState(false);

  return (
    <>
      <Dropdown
        title="Zero tax"
        paddingBottom="pb-2"
        classNameTitle=" text-bbc-brand-800 text-sm leading-bbc-26 font-medium uppercase"
        state={zeroOpened}
        onClick={() => setZeroOpened(!zeroOpened)}
        content={
          <div className="mb-3 flex">
            <ul className="w-1/2  pr-1">
              {countries
                .filter((country) => country.taxRate === TAX_RATE_TYPES.ZERO)
                .slice(0, 7)
                .map((country, index) => (
                  <li key={index} className="mb-2 last:m-0">
                    <LinkRouter
                      className="inline-block text-sm leading-6"
                      href={country.href}
                    >
                      {country.label}
                    </LinkRouter>
                  </li>
                ))}
            </ul>
            <ul className="w-1/2 pl-1 ">
              {countries
                .filter((country) => country.taxRate === TAX_RATE_TYPES.ZERO)
                .slice(7)

                .map((country, index) => (
                  <li key={index} className="mb-2 last:m-0">
                    <LinkRouter
                      className="inline-block text-sm leading-6"
                      href={country.href}
                    >
                      {country.label}
                    </LinkRouter>
                  </li>
                ))}
            </ul>
          </div>
        }
      />
    </>
  );
};

const LowTaxAccordion = () => {
  const [lowOpened, setLowOpened] = useState(false);

  return (
    <>
      <Dropdown
        title="Low tax"
        paddingBottom="pb-2"
        classNameTitle=" text-bbc-brand-800 text-sm leading-bbc-26 font-medium uppercase"
        state={lowOpened}
        onClick={() => setLowOpened(!lowOpened)}
        content={
          <div className="mb-3 flex">
            <ul className="w-1/2  pr-1">
              {countries
                .filter((country) => country.taxRate === TAX_RATE_TYPES.LOW)
                .slice(0, 2)
                .map((country, index) => (
                  <li key={index} className="mb-2 last:m-0">
                    <LinkRouter
                      className="inline-block text-sm leading-6"
                      href={country.href}
                    >
                      {country.label}
                    </LinkRouter>
                  </li>
                ))}
            </ul>
            <ul className="w-1/2 pl-1 ">
              {countries
                .filter((country) => country.taxRate === TAX_RATE_TYPES.LOW)
                .slice(2)
                .map((country, index) => (
                  <li key={index} className="mb-2 last:m-0">
                    <LinkRouter
                      className="inline-block text-sm leading-6"
                      href={country.href}
                    >
                      {country.label}
                    </LinkRouter>
                  </li>
                ))}
            </ul>
          </div>
        }
      />
    </>
  );
};

export default function TaxRateAccordion() {
  const [taxOpened, setTaxOpened] = useState(false);

  return (
    <>
      <Dropdown
        title="Tax Rate"
        paddingBottom="pb-2 "
        classNameTitle="inline-block  font-medium "
        state={taxOpened}
        onClick={() => setTaxOpened(!taxOpened)}
        content={
          <ul className="list-unstyled mb-2 pl-4">
            <li>
              <ZeroTaxAccordion />
            </li>
            <li>
              <LowTaxAccordion />
            </li>
          </ul>
        }
      />
    </>
  );
}
