import { useEffect, useState } from "react";

function useLocalStorage(key, initialValue) {
  // State to store our value

  // Pass initial state function to useState so logic is only executed once

  const [storedValue, setStoredValue] = useState(initialValue);

  // useEffect(() => {
  //   if (typeof window === "undefined") {
  //     setStoredValue(initialValue);
  //   }
  //   try {
  //     // Get from local storage by key
  //     const item = window.localStorage.getItem(key);

  //     if (item) {
  //       setStoredValue(JSON.parse(item));
  //     } else {
  //       const valueToStore =
  //         initialValue instanceof Function ? value(initialValue) : initialValue;

  //       if (typeof window !== "undefined") {
  //         window.localStorage.setItem(key, JSON.stringify(valueToStore));
  //       }
  //       setStoredValue(valueToStore);
  //     }
  //     // Parse stored json or if none return initialValue
  //   } catch (error) {
  //     // If error also return initialValue
  //     console.log(error);
  //     setStoredValue(initialValue);
  //   }
  // }, []);
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state

      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export default useLocalStorage;
