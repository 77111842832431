// Vendor

// Components

import { LayoutContext } from "common/contexts";
import { EXCHANGE } from "common/priceConvert";
import useLocalStorage from "common/useLocalStorage";

import Cookies from "components/common/Layout/Cookies";
import Footer from "components/common/Layout/Footer";
import Header from "components/common/Layout/Header";
import Seo from "components/common/Seo";

/** ========== */

const Layout = ({
  children,
  page,
  locationRouter,
  background,
  isStaticSeo,
  customMenu,
}) => {
  const unit = EXCHANGE;
  const [currencyUnit, setCurrencyUnit] = useLocalStorage(
    "price_unit",
    unit[0]
  );

  return (
    <LayoutContext.Provider
      value={{
        locationRouter,
        currencyUnit,
        unit,
        setCurrencyUnit,
        customMenu,
      }}
    >
      <div className={`${background ? background : "bg-bbc-main"}`}>
        {!isStaticSeo && (
          <Seo page={page || "[]"} locationRouter={locationRouter} />
        )}
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
      <Cookies />
      {/* <FeedBack /> */}
    </LayoutContext.Provider>
  );
};

Layout.defaultProps = {
  isStaticSeo: false,
};

export default Layout;

/** ========== */
